import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

export default function initializeSentry() {
  console.log('Sentry init')

  Sentry.init({
    dsn: "https://65edd3397217a961b5018cac9bc8c4e0@sentry.baladapp.com.br/35",
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({ levels: ['error'] })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0002,
    debug: false,
    environment: process.env.NODE_ENV,

    ignoreErrors: [
      "Network Error",
      "Can't find variable: _AutofillCallbackHandler",
      "Loading chunk 996 failed", // erro integração do chat com zendesk
      "RA-Reputation: bad response from server https://api.reclameaqui.com.br/embed-reputation/", // erro integração reclame aqui
      "Java exception was raised during method invocation",
      "Failed to read the 'localStorage' property from 'Window': Access is denied for this document."

    ]
  });

  if (typeof CURRENT_USER !== 'undefined') {
    console.log('Sentry setUser:', CURRENT_USER)
    Sentry.configureScope((scope) => {
      scope.setUser(CURRENT_USER);
    });
  }
}